<template>
  <b-card class="relative" header="Order Details" header-bg-variant="primary" header-text-variant="white">
    <div>
      <b-button
        size="sm"
        class="ml-auto cursor-pointer mb-2"
        variant="outline-primary"
        style="display: block"
        @click="$router.push('/orders')"
      >
        <feather-icon icon="ArrowLeftCircleIcon" class="mr-25" />
        Go Back
      </b-button>
    </div>
    <template v-if="loaded">
      <OrderDetails v-if="order" :order="order" />
      <OrderItemsTable
          v-if="order" :class="isTableBusy ? 'opacity-50 pointer-events-none' : ''"
          :order="order"
          :refund-method="refundOrder"
          :status-method="changeStatus"
      />
    </template>

    <template v-if="isTableBusy">
      <div class="text-center text-danger my-5">
        <b-spinner class="align-middle mr-1"></b-spinner>
        <strong>Loading...</strong>
      </div>
    </template>
  </b-card>
</template>

<script>
import { BCard, BButton, BSpinner } from "bootstrap-vue";
import { mapActions, mapState } from "vuex";
// eslint-disable-next-line import/no-cycle
import router from "@/router";
import OrderDetails from "@/views/order-management/Components/OrderDetails.vue";
import OrderItemsTable from "@/views/order-management/Components/OrderItemsTable.vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  name: "SingleOrder",
  components: {
    BSpinner,
    BCard,
    OrderDetails,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    OrderItemsTable,
    BButton,
  },
  data() {
    return {
      orderId: null,
      loaded: false,
    };
  },
  computed: {
    ...mapState("ordersModule", ["order", "isTableBusy"]),
  },
  created() {
    this.orderId = router.currentRoute.params.id;
    this.getSingleOrder();
  },
  methods: {
    ...mapActions("ordersModule", ["getOrderById", "refundOrderById", "updateOrderStatus"]),
    async getSingleOrder() {
      this.loaded = false
      try {
        await this.getOrderById(this.orderId);
        this.loaded = true
      } catch (err) {
        console.log(err);
      }
    },
    showToast(message, variant) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: message,
          icon: "BellIcon",
          variant,
        },
      });
    },
    async refundOrder(item) {
      const response = await this.$bvModal.msgBoxConfirm("Are you sure you want to refund this item ?", {
        title: "Please Confirm",
        size: "sm",
        buttonSize: "sm",
        okVariant: "danger",
        okTitle: "YES",
        cancelTitle: "NO",
        footerClass: "p-2",
        hideHeaderClose: false,
        centered: true,
      });

      if (response) {
        try {
          await this.refundOrderById({
            orderId: this.orderId,
            orderItemId: item.id,
          });
          this.showToast("Refund Success", "success");
          this.getSingleOrder();
        } catch (err) {
          this.showToast("Refund failed", "danger");

          console.log(err);
        }
      }
    },
    async changeStatus(item) {
      const payload = {
        orderId: item.orderId,
        orderItemId: item.id,
        statusObject: {
          status: parseFloat(item.status),
        },
      };
      try {
        await this.updateOrderStatus(payload);
        this.showToast("Status Changed", "success");
        this.getSingleOrder();
      } catch (err) {
        this.showToast("Status Change Failed", "danger");
        console.log(err);
      }
    },
  },
};
</script>

<style scoped></style>
