<template>
  <div>
    <!-- Orders table -->
    <b-table
        sticky-header
        bordered
        hover
        size="sm"
        responsive
        class="shadow-sm rounded products-table orders-table"
        thead-tr-class="order-main-thead"
        :items="items"
        :fields="fields"
        :busy="isTableBusy"
        primary-key="id"
        show-empty
        title="Order Details"
        empty-text="No matching records found"
    >
      <!--    <template #cell(actions)="data">-->
      <!--      <b-button variant="outline-danger" size="sm" :disabled="!isUpdateStatusAllowed" @click="refundMethod(data.item)">-->
      <!--        Refund-->
      <!--      </b-button>-->
      <!--    </template>-->
      <template #cell(status)="data">
        <div v-if="data.item.status !== undefined"
            class="d-flex align-items-center"
        >
          <b-form-select
              v-model="data.item.status"
              :disabled="!isUpdateStatusAllowed"
              value-field="value"
              text-field="text"
              class="mr-1"
              size="sm bg-white"
              :options="orderItemStatus"
          />
          <b-button size="sm" variant="success" :disabled="!isUpdateStatusAllowed" @click="statusMethod(data.item)">
            <feather-icon size="10" icon="SaveIcon" />
          </b-button>
        </div>
      </template>
    </b-table>
  </div>
</template>

<script>
import { BTable, BButton, BFormSelect, BForm, BFormGroup, BFormInput } from "bootstrap-vue";
import { mapActions, mapState } from "vuex";
import isAllowed from "@core/utils/isAllowed";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { required } from "@validations";

export default {
  name: "OrderItemsTable",
  components: {
    ValidationProvider,
    ValidationObserver,
    BFormInput,
    BFormGroup,
    BForm,
    BTable,
    BButton,
    BFormSelect,
  },
  props: {
    order: {
      type: Object,
    },
    statusMethod: {
      type: Function,
      default: () => {},
    },
    refundMethod: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      required,
      orderItemStatus: {
        0: this.$i18n.t("orders.preOrdered"),
        8: this.$i18n.t("orders.ordered"),
        1: this.$i18n.t("orders.ready"),
        2: this.$i18n.t("orders.taken"),
        11: this.$i18n.t("orders.finished"),
        12: this.$i18n.t("orders.canceled"),
      },
      orderItemStatusOld: {
        0: "Booked",
        1: "PreOrdered",
        2: "SeenBySeller",
        3: "AcceptedBySeller",
        4: "CancelledBySeller",
        5: "Scheduled",
        6: "AcceptedByReceiver",
        7: "RejectedByReceiver",
        8: "OrderedConfirmed",
        9: "Shipped",
        10: "Delivered",
        11: "Finished",
        12: "Failed",
      },
      fields: [
        {
          label: "status",
          key: "status",
          formatter: (value) => value ? this.orderItemStatus[value] : null,
          class: "status-field",
        },
        {
          label: "orderId",
          key: "orderId",
        },
        {
          label: "Child id",
          key: "id",
        },
        {
          label: "Activation Code",
          key: "activationCode",
        },
        {
          label: "Seller",
          key: "seller",
        },
        {
          label: "Vendor Address",
          key: "vendorAddress",
          formatter: (value, key, item) => item?.vendorAddress ? item?.vendorAddress : '',
        },
        {
          label: "Vendor Mobile",
          key: "vendorMobile",
          formatter: (value, key, item) => item?.vendorMobile ? item?.vendorMobile : '',
        },
        {
          label: "Name",
          key: "nameGeo",
          sortable: false,
          formatter: (value, key, item) => item?.product ? `${item?.product?.nameGeo}  / ${item?.product?.nameEng}` : `${item?.name}`,
        },
        {
          label: "p-id",
          key: "emotyCustomId",
          formatter: (value, key, item) => item?.product?.emotyCustomId ? item?.product?.emotyCustomId : '',
        },
        {
          label: "Service Type",
          key: "serviceType",
          formatter: (value, key, item) => item?.serviceType ? `${this.serviceType[item?.serviceType]}` : '',
        },
        {
          label: "Product price",
          key: "productPrice",
          formatter: (value, key, item) => item?.product?.productPrice ? `${item?.product?.productPrice?.toFixed(2)} ₾` : `${item?.price?.toFixed(2)} ₾`,
        },
        {
          label: "Emoty fee",
          key: "emotyFee",
          formatter: (value, key, item) => item?.product?.emotyFee ? `${item?.product?.emotyFee?.toFixed(2)} ₾` : '',
        },
        {
          label: "Markup",
          key: "markUp",
          formatter: (value, key, item) => item?.markUp ? `${item?.markUp?.toFixed(2)} ₾` : '',
        },
        {
          label: "Bank fee",
          key: "bankFee",
          formatter: (value, key, item) => item?.bankFee ? `${item?.bankFee?.toFixed(2)} ₾` : '',
        },
        {
          label: "Delivery Price",
          key: "deliveryPrice",
        },
        {
          label: "Total Price",
          key: "amount",
          formatter: (value, key, item) => item?.totalPrice ? `${item?.totalPrice?.toFixed(2)} ₾` : '',
        },
        {
          label: "Delivery Address",
          key: "deliveryAddress",
        },
        {
          label: "Review (star)",
          key: "rating",
          formatter: (value, key, item) => item?.feedBackValue ? `${item?.feedBackValue}` : '',
        },
        {
          label: "Review (feedback)",
          key: "ratingText",
          formatter: (value, key, item) => item?.feedBackText ? `${item?.feedBackText}` : '',
        },
      ],
      splitFields: [
          {
            label: "ID",
            key: "id",
          },
          {
            label: this.$i18n.t("orders.vendor"),
            key: "vendor",
          },
          {
            label: this.$i18n.t("orders.originalAmount"),
            key: "originalAmount",
          },
          {
            label: this.$i18n.t("orders.promoCodeAmount"),
            key: "afterPromoCodeAmount",
          },
          {
            label: this.$i18n.t("orders.emotyAmount"),
            key: "emotyPaidAmount",
          },
          {
            label: this.$i18n.t("orders.iban"),
            key: "iban",
          },
          {
            label: this.$i18n.t("orders.isPaid"),
            key: "paid",
          },
          {
            label: this.$i18n.t("orders.payment"),
            key: "payment",
          },
        ],
      items: [],
      splits: [],
    };
  },
  computed: {
    ...mapState("ordersModule", ["isTableBusy"]),
    isUpdateStatusAllowed() {
      // import isAllowed from "@core/utils/isAllowed";
      const roles = JSON.parse(localStorage.getItem("userRoles"));
      return isAllowed(["Admin", "Editor", "Support"], roles);
    },
    serviceType() {
      return {
        1: this.$i18n.t("orders.experiential"),
        2: this.$i18n.t("orders.material"),
        3: this.$i18n.t("orders.digital"),
        4: this.$i18n.t("orders.card"),
      };
    },
  },
  mounted() {
    this.items = []
    this.items = this.order.items;
    this.order.orderAddon.forEach((item) => {
      const order = {
        name: `${item.nameGeo}  / ${item.nameEng}`,
        price: item.price,
      }
      this.items.push(order)
    })

    this.splits = this.order.splits
    // eslint-disable-next-line no-return-assign,no-param-reassign
    this.splits.map(el => el.amount = el.emotyPaidAmount)
  },
  methods: {
    ...mapActions("ordersModule", [
      "manualTransferPerSplit"
    ]),
    updateSplitAmount(split) {
      console.log("split", split)
      this.manualTransferPerSplit({ orderId: this.order.id, amount: split.amount, splitId: split.id })
          .then(() => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Manual transfer saved successfully",
                icon: "RotateCcwIcon",
                variant: "success",
              },
            });
          })
    },
  }
};
</script>

<style scoped lang="scss">
label {
  font-weight: 300;
  margin-bottom: 0;
  color: gray;
}
.b-table-details {
  padding: 0 !important;
}

#basic-info {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;

  div {
    margin: 0 1rem 1rem 0;
  }
}
</style>
